<template>
  <b-modal
    :id="`creative-detail-modal-${creative.id}`"
    :modal-class="modalClasses"
    :hide-footer="true"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1 wrap-text">
        <span>{{ localCreative.name }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <!-- DETAILS -->
      <template v-if="!isEdit">
        <h3 class="font-size-medium mb-3">Dados gerais</h3>
        <p class="mb-4 wrap-text">
          <strong>Nome:</strong> {{ localCreative.name }}<br/>
          <strong>URL de destino:</strong> {{ localCreative.url }}<br/>
          <strong>Formato:</strong> {{ localCreative.format | formatCreativeFormat }}<br/>
          <strong>Tamanho:</strong> {{ localCreative.layout }}<br/>
          <template v-if="localCreative.format === 'NATIVE_DISPLAY'">
            <strong>Nome de exibição do anunciante:</strong> {{ localCreative.advertiser_display_name }}<br/>
            <strong>Título:</strong> {{ localCreative.headline }}<br/>
            <strong>Corpo do texto:</strong> {{ localCreative.body_text }}<br/>
            <strong>Call to action:</strong> {{ localCreative.call_to_action }}<br/>
            <strong>URL de exibição:</strong> {{ localCreative.caption_url }}<br/>
          </template>
          <!--<strong>Disponibilidade:</strong> {{ availability }}<br/> -->
          <strong>Status:</strong> {{ localCreative.status | formatStatus }}
        </p>
        <h3 class="font-size-medium">Criativo</h3>
        <div class="text-center">
          <a :href="creative.file" target="_blank">
            <img :src="creative.file" :alt="creative.name" />
          </a>
        </div>
        <template v-if="localCreative.logo">
          <h3 class="font-size-medium mt-3">Logo</h3>
          <div class="text-center">
            <a :href="creative.logo" target="_blank">
              <img :src="creative.logo" :alt="creative.name" width="100" />
            </a>
          </div>
        </template>
        <div class="text-center mt-4">
          <Button
            class="btn-primary"
            @click="isEdit = true"
          >
            Editar
          </Button>
        </div>
      </template> <!-- END DETAILS -->
      <!-- EDIT -->
      <template v-else>
        <UpsertCreativeForm
          :advertiserId="localCreative.advertiser.id"
          :creative="localCreative"
          :format="localCreative.format"
          @creative-updated="handleUpdated"
          @upsert-creative-error="handleError"
        />
      </template> <!-- END EDIT -->
    </div>
  </b-modal>
</template>

<script>

import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import UpsertCreativeForm from '@/components/creatives/UpsertCreativeForm'

export default {
  name: 'CreativeDetailModal',
  props: ['creative'],
  components: {
    AlertWraper,
    Button,
    UpsertCreativeForm
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      isEdit: false,
      loader: false,
      localCreative: null
    }
  },
  created () {
    this.localCreative = this.creative
  },
  computed: {
    availability () {
      const availability = []
      if (this.localCreative.is_desktop) availability.push('Desktop')
      if (this.localCreative.is_mobile) availability.push('Mobile')
      if (this.localCreative.is_app) availability.push('Aplicativos')

      return availability.length > 0 ? availability.join(', ') : 'Sem disponibilidade'
    },
    modalClasses () {
      const classes = ['default-modal']
      if (this.isEdit && this.localCreative.format === 'NATIVE_DISPLAY') classes.push('modal-size-lg')
      return classes.join(' ')
    }
  },
  methods: {
    /**
     * Handle updated
     */
    handleUpdated (creative) {
      this.isEdit = false
      this.localCreative = creative
      this.content = null
    },
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * On hidden
     */
    hidden () {
      this.isEdit = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
